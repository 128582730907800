import React, { Component } from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import query from './ProductsQuery.gql';
import get from 'lodash.get';
import CategoryLink from '@jetshop/ui/CategoryLink';
import Container from '../Layout/Container';
import UIProductGrid from '../ui/ProductGrid';
import { TrendLink, TrendButtonStyle } from '../ui/Button';
import TitleRow from '../ui/TitleRow';
import { ReactComponent as ArrowRight } from '../../svg/arrow-circle-right.svg';
import ProductCard from '../ui/ProductItems/ProductCard';

const Row = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledTrendLink = styled(TrendLink)`
  margin-top: 20px;
  width: auto;
`;

const StyledCategoryLink = styled(CategoryLink)`
  display: inline-block;
  text-decoration: none;
  text-align: center;
  ${TrendButtonStyle}
  margin-top: 20px;
  width: auto;
`;

const IconWrapper = styled('div')`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 10px;

  svg {
    height: 18px;
    width: 18px;
    margin-bottom: -3px;

    g,
    mask,
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

const StyledProductGrid = styled(UIProductGrid)`
  margin: 40px -5px 80px -5px;
`;

const ProductWrapper = styled('div')`
  padding: 5px;
  text-align: center;
  outline: 0;
  box-shadow: none;
`;

const StyledProductCard = styled(ProductCard)`
  height: 100%;
`;

const Grid = ({
  id,
  marginTop,
  title,
  topTitle,
  products,
  link,
  showProducts
}) => (
  <Container id={id} maxWidth marginTop={marginTop}>
    <TitleRow topTitle={topTitle} title={title} />

    {products && (
      <StyledProductGrid>
        {products
          .filter((product, i) => {
            if (showProducts) {
              return i < showProducts;
            } else {
              return true;
            }
          })
          .map(product => (
            <ProductWrapper key={product.id}>
              <StyledProductCard product={product} />
            </ProductWrapper>
          ))}
      </StyledProductGrid>
    )}

    {link && (
      <Row>
        {link.category ? (
          <StyledCategoryLink category={link.category} secondary={1}>
            {link.title}
            <IconWrapper>
              <ArrowRight />
            </IconWrapper>
          </StyledCategoryLink>
        ) : (
          <StyledTrendLink to={link.to} secondary={1}>
            {link.title}
            <IconWrapper>
              <ArrowRight />
            </IconWrapper>
          </StyledTrendLink>
        )}
      </Row>
    )}
  </Container>
);

class ProductGrid extends Component {
  render() {
    let { products, categoryId, ...rest } = this.props;

    if (products) {
      return <Grid {...rest} products={products} />;
    } else if (categoryId) {
      return (
        <Query query={query} variables={{ id: categoryId }}>
          {({ data }) => {
            const { category } = data;
            let products = get(category, 'products.result');

            let link = {
              title: 'Utforska fler',
              category: category
            };

            //Mobile display
            let numOfProducts = products ? products.length : 0;
            const maxProducts = 8;
            const minProducts = 4;

            let showProducts = maxProducts;
            if (numOfProducts < maxProducts && numOfProducts > minProducts) {
              // ODD
              if (numOfProducts & 1) {
                numOfProducts--;
              }
            }
            return numOfProducts ? (
              <Grid
                link={link}
                showProducts={showProducts}
                {...rest}
                products={products}
              />
            ) : null;
          }}
        </Query>
      );
    } else {
      return null;
    }
  }
}

export default ProductGrid;
