import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import query from './InspirationEntries.gql';
import Masonry from 'react-masonry-component';
import EntryCard from '../ui/EntryCard';
import Container from '../Layout/Container';
import { scanArrayOfObjects, getServer } from '../utility/functions';

const StyledMasonry = styled(Masonry)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 0px;
  margin-left: -10px;
  margin-right: -10px;
  transition: all 250ms ease;

  ${({ theme }) => theme.above.sm} {
    padding: 15px 0px;
    margin-left: -15px;
    margin-right: -15px;
  }

  ${({ theme }) => theme.above.lg} {
    padding: 25px 0px;
    margin-left: -25px;
    margin-right: -25px;
  }

  ${({ theme }) => theme.above.xl} {
    padding: ${({ threeCols }) => (!threeCols ? '35px 0px' : '25px 0px')};
    margin-left: ${({ threeCols }) => (!threeCols ? '-50px' : '-25px')};
    margin-right: ${({ threeCols }) => (!threeCols ? '-50px' : '-25px')};
  }
`;

const CardWrapper = styled('div')`
  width: 100%;
  padding: 10px;
  transition: all 250ms ease;

  ${({ theme }) => theme.above.sm} {
    width: 50%;
    padding: 15px;
  }

  ${({ theme }) => theme.above.md} {
    width: ${({ threeCols }) => (threeCols ? '33.33%' : '50%')};
  }

  ${({ theme }) => theme.above.lg} {
    padding: 25px;
  }

  ${({ theme }) => theme.above.xl} {
    padding: ${({ threeCols }) => (!threeCols ? '35px 50px' : '25px')};
  }
`;

const Inner = styled('div')`
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.below.sm} {
    max-width: 450px;
  }
`;

const RenderEntries = ({ id, marginTop, entries, threeCols }) => (
  <Container id={id} maxWidth marginTop={marginTop}>
    <StyledMasonry>
      {entries &&
        entries.map((entry, i) => {
          if (entry.image) {
            if (i % 2 === 0) {
              entry.image.aspect = '6:7';
            } else {
              entry.image.aspect = '7:5';
            }
          }

          return (
            <CardWrapper key={i} threeCols={threeCols}>
              <Inner>
                <EntryCard numOfCols={2} {...entry} />
              </Inner>
            </CardWrapper>
          );
        })}
    </StyledMasonry>
  </Container>
);

class EntriesMasonry extends Component {
  render() {
    let { id, marginTop, entries } = this.props;

    if (!entries) {
      return null;
    }

    const useQuery = scanArrayOfObjects('categoryId', entries);
    let categoryId = null;

    if (useQuery) {
      const _server = getServer();
      categoryId = _server.inspoCatId;
    }

    const threeCols = entries.length === 3 || entries.length > 4;

    return useQuery ? (
      <Query variables={{ id: categoryId }} query={query}>
        {result => {
          const rawCategories = _.get(result.data, 'categories');
          let updatedEntries = [];

          if (rawCategories && entries) {
            const orderedCategories = _.keyBy(rawCategories, 'id');

            updatedEntries = entries.map((item, i) => {
              if (item.categoryId !== undefined) {
                const current = orderedCategories[item.categoryId];
                if (current) {
                  let { id, name, mainHeader, images } = current;
                  const topTitle = mainHeader !== name ? mainHeader : '';

                  return {
                    id: id,
                    topTitle: topTitle,
                    title: name,
                    link: {
                      to: current.primaryRoute.path
                    },
                    image: images
                      ? {
                          url: images[images.length - 1].url,
                          alt: name
                        }
                      : null,
                    category: current
                  };
                }
              }

              return item;
            });
          }

          return (
            <RenderEntries
              id={id}
              marginTop={marginTop}
              entries={updatedEntries}
              threeCols={threeCols}
            />
          );
        }}
      </Query>
    ) : (
      <RenderEntries
        id={id}
        marginTop={marginTop}
        entries={entries}
        threeCols={threeCols}
      />
    );
  }
}

export default EntriesMasonry;
