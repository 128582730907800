import React, { Component } from 'react';
import { getStringByPropertyName } from './ContentHelper';
import Newsletter from '../Content/Newsletter';

const NewsletterBlock = ({ properties, children }) => {
  const title = getStringByPropertyName(properties, 'title');
  const subTitle = getStringByPropertyName(properties, 'subTitle');
  const marginTop = getStringByPropertyName(properties, 'marginTop');
  const marginBottom = getStringByPropertyName(properties, 'marginBottom');
  return (
    <Newsletter
      title={subTitle}
      topTitle={title}
      marginTop={marginTop}
      marginBottom={marginBottom}
    ></Newsletter>
  );
};

export default NewsletterBlock;
