import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { default as Image } from '@jetshop/ui/Image';
import Container from '../Layout/Container';
import Text from '../ui/Text';
import ImageBackground from '../ui/ImageBackground';
import { TrendLink } from '../ui/Button';
import { imageObjConstruct } from '../utility/functions';
import { ReactComponent as ArrowRight } from '../../svg/arrow-circle-right.svg';

const Inner = styled('div')`
  position: relative;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  width: 100%;
  text-align: center;
  min-height: 400px;
  padding: 30px 10px;
  transition: all 250ms ease;

  ${({ theme }) => theme.above.sm} {
    padding: 30px 15px;
    text-align: left;

    ${({ textPosition }) => {
      switch (textPosition) {
        case 'center':
          return css`
            justify-content: center;
            text-align: center;
          `;

        case 'right':
          return css`
            justify-content: flex-end;
            text-align: right;
          `;

        default:
          return css`
            justify-content flex-start;
            text-align: left;
          `;
      }
    }}
  
  ${({ theme }) => theme.above.md} {
    padding: 30px 50px;
  }

  ${({ theme }) => theme.above.lg} {
    padding: 60px 100px;
  }
`;

const Content = styled('div')`
  width: 100%;
  max-width: 450px;
  color: ${({ darkText, theme }) =>
    darkText ? theme.colors.black : theme.colors.white};

  ${({ theme }) => theme.below.sm} {
    margin: 0 auto;
  }
`;

const StyledText = styled(Text)`
  line-height: 1.38;
  color: ${({ darkText, theme }) =>
    darkText ? theme.colors.black : theme.colors.white};
`;

const Title = styled('h2')`
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;

  &::after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 80px;
    height: 2px;
    transform: translateX(-50%);
    background-color: ${({ darkText, theme }) =>
      darkText ? theme.colors.black : theme.colors.white};

    ${({ theme }) => theme.above.sm} {
      ${({ textPosition }) => {
        switch (textPosition) {
          case 'center':
            return css`
              left: 50%;
              transform: translateX(-50%);
            `;

          case 'right':
            return css`
              right: 0px;
              transform: translateX(0%);
            `;

          default:
            return css`
              left: 0px;
              transform: translateX(0%);
            `;
        }
      }}
  }
`;

const StyledTrendLink = styled(TrendLink)`
  margin-top: 20px;
`;

export const IconWrapper = styled('div')`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 10px;

  svg {
    height: 18px;
    width: 18px;
    margin-bottom: -3px;

    g,
    mask,
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

class CTA extends Component {
  render() {
    let {
      id,
      marginTop,
      title,
      text,
      textPosition,
      darkText,
      link,
      imageSrc,
      image
    } = this.props;

    const imageExtra = {
      sizes: [1, 1, 1, 1, 1400],
      crop: true,
      fillAvailableSpace: true
    };

    const imageArgs = imageObjConstruct(imageSrc, image, imageExtra);

    return (
      <Container id={id} maxWidth marginTop={marginTop}>
        <Inner textPosition={textPosition}>
          {image && (
            <ImageBackground>
              <Image {...imageArgs} />
            </ImageBackground>
          )}

          <Content darkText={darkText}>
            {title && (
              <Title darkText={darkText} textPosition={textPosition}>
                {title}
              </Title>
            )}

            {text && <StyledText darkText={darkText} content={text} />}

            {link && (
              <StyledTrendLink
                to={link.to}
                target={link.targetBlank}
                primary={1}
              >
                {link.title}
                <IconWrapper>
                  <ArrowRight />
                </IconWrapper>
              </StyledTrendLink>
            )}
          </Content>
        </Inner>
      </Container>
    );
  }
}

export default CTA;
