import React from 'react';
import styled, { css } from 'react-emotion';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';
import { IconButton } from '../../ui/Button';
import { ReactComponent as SubscribeIcon } from '../../../svg/envelope-up.svg';

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 450px;
  background: transparent;

  ${({ theme }) => theme.below.md} {
    margin: 0 auto;
    padding-bottom: 65px;
  }
`;

const Form = styled('form')`
  flex-grow: 1;

  div {
    display: flex;
    align-items: flex-end;

    input {
      ${({ theme }) => theme.above.md} {
        margin-right: 30px;
      }
    }
  }

  input {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    padding-bottom: 10px;
    border: 0px;
    border-radius: 0px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
    box-shadow: none;
    background: transparent;
    ${({ theme }) => theme.fontSizes.regular}
    letter-spacing: 2px;
    text-transform: uppercase;
    outline: 0;

    &::placeholder {
      color: ${({ theme }) => theme.colors.lightGrey};
    }

    ${({ theme }) => theme.below.md} {
      text-align: center;
    }
  }
`;

const ButtonWrapper = styled('div')`
  ${({ theme }) => theme.below.md} {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.gradientBlack1};
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 47px;
  height: 47px;
  button {
    background: ${({ theme }) => theme.colors.white};
    transition: all 0.4s ease-out;
  }

  button:hover {
    scale: 1.03;
  }

  svg g {
    fill: ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.above.xl} {
    width: 60px;
    height: 60px;
  }
`;

const CheckboxLabel = styled('label')`
  position: relative;
  display: inline-block;
  margin-top: 20px;
  padding-left: 30px;
  cursor: pointer;

  .text {
    user-select: none;

    a {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  .checkbox {
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 16px;
    height: 16px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    transform: translateY(-50%);

    &:before {
      display: block;
      content: '';
      position: absolute;
      bottom: 50%;
      left: 7%;
      width: 45%;
      height: 80%;
      max-width: 0%;
      max-height: 0%;
      opacity: 0;
      border-width: 0px 2px 2px 0px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.white};
      transform-origin: left bottom;
      transform: rotate(45deg);
      transition: all 0ms ease;
    }
  }

  //Marking css for new checkbox
  input[type='checkbox']:checked ~ .checkbox:before {
    max-width: 45%;
    max-height: 80%;
    opacity: 1;
    transition: opacity 0ms linear 0ms, max-width 35ms ease-in 0ms,
      max-height 35ms ease-out 35ms;
  }
`;

const Message = styled('div')`
  width: 100%;
  padding: 10px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.lightGrey};
  box-shadow: ${({ theme }) => theme.boxshadow};
`;

const Error = styled(Message)`
  border-left: 10px solid ${({ theme }) => theme.colors.peach};
  margin-bottom: 10px;
`;

const Sucess = styled(Message)`
  border-left: 10px solid ${({ theme }) => theme.colors.gradientBlue};
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    confirmed: false,
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  updateConfirmation = e => this.setState({ confirmed: !this.state.confirmed });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    let { placeholder, confirmText } = this.props;

    placeholder = placeholder ? placeholder : 'E-mejladress...';
    confirmText = confirmText
      ? confirmText
      : 'Jag godkänner härmed att Nya Musik (556059-8426) får samla in, använda och behandla mina personuppgifter som jag har angivit i detta formulär i enlighet med vår <a href="/personuppgifter">Integritetspolicy</a>.';

    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => {
          return (
            <Wrapper>
              {this.state.error && (
                <Error>
                  {getErrorDetail(result.error).codes.includes(
                    'AlreadySubscribed'
                  )
                    ? 'Du är redan en prenumerant av det här nyhetsbrevet!'
                    : 'Något gick fel. Kontrollera din e-postadress och försök igen.'}
                </Error>
              )}

              {this.state.completed ? (
                <Sucess>Tack för din prenumeration, vi hörs snart!</Sucess>
              ) : (
                <Form onSubmit={submitEmail(this.state.email, subscribe)}>
                  <div>
                    <input
                      type="email"
                      placeholder={placeholder}
                      onChange={this.updateEmail}
                      className={css`
                        padding-right: ${result.loading ? '8rem' : '4rem'};
                      `}
                    />

                    <ButtonWrapper>
                      <StyledIconButton
                        type="submit"
                        disabled={!this.state.confirmed}
                      >
                        <SubscribeIcon />
                      </StyledIconButton>
                    </ButtonWrapper>
                  </div>

                  <CheckboxLabel htmlFor="gdpr" className="description-size">
                    <input
                      id="gdpr"
                      type="checkbox"
                      onChange={this.updateConfirmation}
                    />
                    <span className="checkbox" />
                    <span
                      className="text"
                      dangerouslySetInnerHTML={{ __html: confirmText }}
                    />
                  </CheckboxLabel>
                </Form>
              )}
            </Wrapper>
          );
        }}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
