import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { default as Image } from '@jetshop/ui/Image';
import Container from '../Layout/Container';
import Text from '../ui/Text';
import { IconWrapper } from './CTA';
import { TrendLink, TrendButtonStyle } from '../ui/Button';
import { imageObjConstruct } from '../utility/functions';
import { ReactComponent as ArrowRight } from '../../svg/arrow-circle-right.svg';

const Wrapper = styled(Container)`
  align-items: center;

  ${({ theme }) => theme.above.sm} {
    flex-direction: row;
  }
`;

const Col = styled('div')`
  width: 100%;

  ${({ theme }) => theme.above.sm} {
    width: 50%;
  }

  ${({ theme }) => theme.above.lg} {
    width: ${({ largeImage }) => (largeImage ? '40%' : '50%')};
  }
`;

const ImageCol = styled(Col)`
  ${({ theme }) => theme.below.sm} {
    margin: ${({ position }) =>
      position === 'left' ? '30px 0px 0px 0px' : '0px 0px 30px 0px'};
  }

  ${({ theme }) => theme.above.sm} {
    order: ${({ position }) => (position === 'left' ? '1' : '2')};
    padding: ${({ position }) =>
      position === 'left' ? '0px 15px 0px 0px' : '0px 0px 0px 15px'};
  }

  ${({ theme }) => theme.above.lg} {
    width: ${({ largeImage }) => (largeImage ? '60%' : '50%')};
    padding: ${({ position }) =>
      position === 'left' ? '0px 25px 0px 0px' : '0px 0px 0px 25px'};
  }
`;

const TextCol = styled(Col)`
  ${({ theme }) => theme.above.sm} {
    order: ${({ position }) => (position === 'left' ? '2' : '1')};
    padding: ${({ position }) =>
      position === 'left' ? '0px 0px 0px 15px' : '0px 15px 0px 0px'};
  }

  ${({ theme }) => theme.above.lg} {
    padding: ${({ position }) =>
      position === 'left' ? '0px 0px 0px 25px' : '0px 25px 0px 0px'};
  }

  //Button
  & > a {
    margin-top: 30px;
  }
`;

const TopTitle = styled('h3')`
  margin-bottom: 5px;
`;

const Title = styled('h2')`
  margin-bottom: 20px;
  font-weight: 700;
`;

const StyledTrendLink = styled(TrendLink)`
  width: auto;
  min-width: 200px;
`;

const StyledCategoryLink = styled(CategoryLink)`
  display: inline-block;
  text-decoration: none;
  text-align: center;
  ${TrendButtonStyle}
  width: auto;
  min-width: 200px;
`;

class TextImage extends Component {
  render() {
    let {
      id,
      marginTop,
      title,
      largeTitle,
      topTitle,
      text,
      largeText,
      largeImage,
      link,
      imagePosition,
      imageSrc,
      image,
      category
    } = this.props;

    const titleClasses = [largeTitle ? 'mega-size' : 'hero-size'];

    let xlSize = largeImage ? 840 : 700;
    let lgSize = largeImage ? 5 / 3 : 1 / 2;
    const imageExtra = {
      sizes: [1, 1, 1 / 2, lgSize, xlSize],
      cover: true,
      aspect: '7:5'
    };
    const imageArgs = imageObjConstruct(imageSrc, image, imageExtra);

    return (
      <Wrapper id={id} maxWidth marginTop={marginTop}>
        {image && (
          <ImageCol position={imagePosition} largeImage={largeImage}>
            <Image {...imageArgs} />
          </ImageCol>
        )}

        <TextCol position={imagePosition} largeImage={largeImage}>
          {topTitle && <TopTitle>{topTitle}</TopTitle>}

          {title && <Title className={titleClasses.join(' ')}>{title}</Title>}

          {text && <Text content={text} largeText={largeText} />}

          {link && (
            <Fragment>
              {category ? (
                <StyledCategoryLink
                  category={category}
                  secondary={link.primary ? 0 : 1}
                  primary={link.primary ? 1 : 0}
                >
                  {link.title}
                  {link.icon && (
                    <IconWrapper>
                      <ArrowRight />
                    </IconWrapper>
                  )}
                </StyledCategoryLink>
              ) : (
                <StyledTrendLink
                  to={link.to}
                  target={link.targetBlank}
                  secondary={link.primary ? 0 : 1}
                  primary={link.primary ? 1 : 0}
                >
                  {link.title}
                  {link.icon && (
                    <IconWrapper>
                      <ArrowRight />
                    </IconWrapper>
                  )}
                </StyledTrendLink>
              )}
            </Fragment>
          )}
        </TextCol>
      </Wrapper>
    );
  }
}

export default TextImage;
