import React, { Component } from 'react';
import { getStringByPropertyName } from './ContentHelper';
import ProductCarousel from '../Content/ProductCarousel';
import HelloRetailRecommendations from '../HelloRetail';

const HelloRetailBlock = ({ properties, children }) => {
  const title = getStringByPropertyName(properties, 'title');
  const recommendationId = getStringByPropertyName(
    properties,
    'recommendationId'
  );
  const recList = [
    { title: title, recommendationId: recommendationId, slider: 'true' }
  ];

  return <HelloRetailRecommendations recommendationsList={recList} />;
};

export default HelloRetailBlock;
