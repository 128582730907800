import styled from 'react-emotion';

const ImageBackground = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;
export default ImageBackground;
