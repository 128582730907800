import React from 'react';
import styled from 'react-emotion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';

const Subcategories = styled('ul')`
  display: flex;
  flex-wrap: wrap;

  padding: 0 15px;

  ${({ theme }) => theme.above.lg} {
    padding: 0 60px;
  }
`;

const Subcategory = styled('li')`
  flex: 1;
  padding-right: 50%;

  ${({ theme }) => theme.above.sm} {
    padding: 0 30px;
  }

  .title {
    a {
      font-weight: bold;
    }
  }

  a {
    width: 100%;
    display: inline-block;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    padding: 10px 5px;
  }

  a:hover {
    text-decoration: underline;
  }

  min-width: 100%;
  max-width: 100%;

  ${({ theme }) => theme.above.sm} {
    min-width: 33.33%;
    max-width: 33.33%;
  }

  ${({ theme }) => theme.above.md} {
    min-width: 25%;
    max-width: 25%;
  }

  ${({ theme }) => theme.above.xl} {
    min-width: 20%;
    max-width: 20%;
  }
`;

const FeaturedFilters = styled('ul')`
  margin-bottom: 15px;

  ${({ theme }) => theme.above.sm} {
    margin-bottom: 60px;
  }

  .show-all {
    a {
      text-decoration: underline;
    }
  }
`;

const Filter = styled('li')``;

const LogoContainer = styled('div')`
  background: ${({ theme }) => theme.colors.lightGrey};
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const CategoryLogo = styled('img')`
  max-width: 150px;
  max-height: 40px;
  transition: all 250ms ease;
`;

const SubcategoriesLayout = ({ subcategories }) => {
  const processedSubcategories = sortBy(subcategories, 'name').map(cat => {
    const keywords = cat?.head?.metaTags.find(t => t.name === 'keywords');
    let featuredFilters = [];
    if (keywords) {
      try {
        featuredFilters = JSON.parse(keywords.content);
      } catch (e) {
        console.warn(e);
      }
    }
    return { ...cat, featuredFilters };
  });

  const hasImages = processedSubcategories.some(cat => cat.images.length);

  return (
    <Subcategories>
      {processedSubcategories.map(cat => (
        <Subcategory key={cat.id}>
          {hasImages && (
            <LogoContainer>
              {cat.images.length ? (
                <CategoryLogo src={cat.images[0].url}></CategoryLogo>
              ) : null}
            </LogoContainer>
          )}
          <div className="title">
            <CategoryLink category={cat}>{cat.name}</CategoryLink>
          </div>
          {cat.featuredFilters.length
            ? [
                <FeaturedFilters>
                  {cat.featuredFilters.map(filter => (
                    <Filter key={filter.url}>
                      <Link to={filter.url}>{filter.title}</Link>
                    </Filter>
                  ))}
                  <div class="show-all">
                    <CategoryLink category={cat}>Visa alla</CategoryLink>
                  </div>
                </FeaturedFilters>
              ]
            : null}
        </Subcategory>
      ))}
    </Subcategories>
  );
};

export default SubcategoriesLayout;
