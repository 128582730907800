import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import Container from '../Layout/Container';
import { getStringByPropertyName } from './ContentHelper';
import { FastArrowRight } from 'iconoir-react';
import { theme } from '../Theme';
import Link from '../utility/Link';

const ContainerWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 4rem 0;

  ${theme.below.md} {
    padding: 2rem 0;
  }

  h1 {
    color: #757e7f;
    font-size: min(calc(1vw + 1.6rem), 42px);
    font-weight: bold;
    line-height: 1.23;
  }

  div {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  ${theme.below.sm} {
    gap: 1rem;
  }

  a {
    text-decoration: none;
  }

  button {
    padding: 1rem 3rem;
    background-color: #757e7f;
    border-radius: 100px;
    color: white;
    font-size: min(calc(1vw + 1.02rem), 1.8rem);
    font-weight: bold;
    transition: all 0.4s ease-out;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  button:hover {
    background-color: #8f9798;
    scale: 1.02;
  }
`;

const CategoryButtons = ({ properties, children }) => {
  const title = properties[0]?.value?.string;
  return (
    <ContainerWrapper>
      <div>
        <h1>{title}</h1>
      </div>
      <ButtonContainer>
        {children.map((child, key) => {
          const link = getStringByPropertyName(child?.properties, 'link');
          const title = getStringByPropertyName(child?.properties, 'ctaTitle');
          return (
            <Link to={link} key={key}>
              <button>
                {title}
                <FastArrowRight></FastArrowRight>
              </button>
            </Link>
          );
        })}
      </ButtonContainer>
    </ContainerWrapper>
  );
};

export default CategoryButtons;
