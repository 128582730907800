import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import React from 'react';
import { css } from 'react-emotion';
import Container from '../Layout/Container';
import { CategoryHeaderWrapper } from './CategoryHeader';

export default React.memo(function CategoryHeaderLoadingState() {
  const bgColor = '#f1f1f1';
  return (
    <CategoryHeaderWrapper>
      <Container maxWidth>
        <LoadingLine
          color={bgColor}
          widthRem={20}
          heightPx={14}
          className={css`
            margin: 0px auto 20px;
          `}
        />
        <LoadingLine
          color={bgColor}
          widthRem={40}
          heightPx={50}
          className={css`
            margin: 0px auto 10px;
          `}
        />
        <LoadingLine
          color={bgColor}
          widthRem={15}
          heightPx={14}
          className={css`
            margin: 0px auto 20px;
          `}
        />
      </Container>
    </CategoryHeaderWrapper>
  );
});
