import React from 'react';

import {
  BANNER,
  BLURB,
  CATEGORY_BUTTONS,
  HELLORETAIL,
  IMAGE_GRID,
  NEWSLETTER,
  PRODUCT_CAROUSEL
} from './ElementTypes';
import CategoryButtons from './CategoryButtons';
import Blurb from './Blurb';
import Banner from './Banner';
import ImageGrid from './ImageGrid';
import ProductCarouselBlock from './ProductCarouselBlock';
import NewsletterBlock from './NewsletterBlock';
import HelloRetailBlock from './HelloRetailBlock';

// function getFields(item) {
//    let output = {}

//    item?.properties?.forEach(field => {
//       // Look for value
//       let value = undefined
//       if (field?.value?.string) {
//          value = field.value.string
//       }
//       if (field?.value?.bool) {
//          value = field.value.bool
//       }

//       if (value !== undefined) {
//          output[field.name] = value
//       }
//    })

//    return output
// }

// // Map to new object to make writing components more smood
// function mapItem(item) {
//    let output = {
//       type: item.type,
//       fields: {},
//       children: [],
//    }

//    output.properties = getFields(item)

//    if (item.children) {
//       output.properties.children = item.children.map(child => getFields(child))
//    }

//    return output
// }

/**
 * @typedef {object} Props
 * @property {array} items - custom element items
 * @property {object} category - current category, used by some custom components
 *
 * Renders all custom elements given as 'items'
 * @param {Props} props
 */
export const RenderCustomElements = ({ items }) => {
  if (!items) return null;
  return items.map((item, key) => {
    switch (item.type) {
      case CATEGORY_BUTTONS:
        return (
          <CategoryButtons
            key={key}
            properties={item?.properties}
            children={item?.children}
          ></CategoryButtons>
        );
      case BLURB:
        return (
          <Blurb
            key={key}
            properties={item?.properties}
            children={item?.children}
          ></Blurb>
        );
      case BANNER:
        return (
          <Banner
            key={key}
            properties={item?.properties}
            children={item?.children}
          ></Banner>
        );
      case IMAGE_GRID:
        return (
          <ImageGrid
            key={key}
            properties={item?.properties}
            children={item?.children}
          ></ImageGrid>
        );
      case PRODUCT_CAROUSEL:
        return (
          <ProductCarouselBlock
            key={key}
            properties={item?.properties}
            children={item?.children}
          ></ProductCarouselBlock>
        );
      case NEWSLETTER:
        return (
          <NewsletterBlock
            key={key}
            properties={item?.properties}
            children={item?.children}
          ></NewsletterBlock>
        );
      case HELLORETAIL:
        return (
          <HelloRetailBlock
            key={key}
            properties={item?.properties}
            children={item?.children}
          ></HelloRetailBlock>
        );

      default:
        console.log(`No custom element for ${item.type}`);
        return null;
    }
  });
};

export default RenderCustomElements;
