import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import Container from '../Layout/Container';
import { getStringByPropertyName } from './ContentHelper';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../Theme';
import Link from '../utility/Link';

const ContainerWrapper = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 4rem;
  gap: 5rem;
  ${theme.above.md} {
    flex-direction: row;
    gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 6rem;
  }
  img {
    border-radius: 0.5rem;
  }
`;

const Card = styled('div')`
  width: 100%;
  position: relative;
  border-radius: 0.5rem;
  transition: all 0.4s ease-out;
  &:hover {
    scale: 1.01;
  }
  a {
    color: inherit;
  }
`;

const CtaDiv = styled('div')`
  transition: all 0.2s ease-in;
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  background-color: #ffffff;
  text-align: center;
  padding: 1rem 4rem;
  border-radius: 100px;

  ${theme.below.sm} {
    font-size: 14px;
  }
`;

const Blurb = ({ properties, children }) => {
  return (
    <ContainerWrapper>
      {children.map((child, i) => {
        const link = getStringByPropertyName(child?.properties, 'link');
        const title = getStringByPropertyName(child?.properties, 'ctaTitle');
        const imgUrl = getStringByPropertyName(child?.properties, 'image');
        const imgAlt = getStringByPropertyName(child?.properties, 'alt');
        return (
          <Card className="st-shadow" key={i}>
            <Link to={link}>
              <Image
                src={imgUrl}
                alt={imgAlt}
                aspect="7:5"
                cover={true}
              ></Image>
              <CtaDiv className="st-shadow">
                <span>{title}</span>
              </CtaDiv>
            </Link>
          </Card>
        );
      })}
    </ContainerWrapper>
  );
};

export default Blurb;
