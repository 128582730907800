import React, { Component } from 'react';
import { getStringByPropertyName } from './ContentHelper';
import ProductCarousel from '../Content/ProductCarousel';

const ProductCarouselBlock = ({ properties, children }) => {
  const categoryId = getStringByPropertyName(properties, 'categoryId');
  const title = getStringByPropertyName(properties, 'title');
  const marginTop = getStringByPropertyName(properties, 'marginTop');
  const marginBottom = getStringByPropertyName(properties, 'marginBottom');
  return (
    <ProductCarousel
      categoryId={categoryId}
      title={title}
      marginTop={marginTop}
      marginBottom={marginBottom}
    ></ProductCarousel>
  );
};

export default ProductCarouselBlock;
