import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import query from './InspirationEntries.gql';
import EntryCard from '../ui/EntryCard';
import TitleRow from '../ui/TitleRow';
import Container from '../Layout/Container';
import { scanArrayOfObjects, getServer } from '../utility/functions';

const StyledTitleRow = styled(TitleRow)`
  margin-bottom: 60px;
`;

const Inner = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -10px;
  transition: all 250ms ease;

  ${({ theme }) => theme.above.sm} {
    margin: -15px;
  }

  ${({ theme }) => theme.above.lg} {
    margin: -25px;
  }

  ${({ theme }) => theme.above.xl} {
    margin: ${({ threeCols }) => (!threeCols ? '-50px' : '-25px')};
  }
`;

const CardWrapper = styled('div')`
  width: 100%;
  padding: 10px;
  transition: all 250ms ease;

  ${({ theme }) => theme.below.sm} {
    max-width: 450px;
  }

  ${({ theme }) => theme.above.sm} {
    width: 50%;
    padding: 15px;
  }

  ${({ theme }) => theme.above.md} {
    width: ${({ threeCols }) => (threeCols ? '33.33%' : '50%')};
  }

  ${({ theme }) => theme.above.lg} {
    padding: 25px;
  }

  ${({ theme }) => theme.above.xl} {
    padding: ${({ threeCols }) => (!threeCols ? '35px 50px' : '25px')};
  }
`;

const RenderEntries = ({
  id,
  marginTop,
  topTitle,
  title,
  threeCols,
  entries
}) => (
  <Container id={id} maxWidth marginTop={marginTop}>
    <StyledTitleRow topTitle={topTitle} title={title} />

    <Inner threeCols={threeCols}>
      {entries &&
        entries.length > 0 &&
        entries.map((entry, i) => {
          return (
            <CardWrapper key={i} threeCols={threeCols}>
              <EntryCard numOfCols={threeCols ? 3 : 2} {...entry} />
            </CardWrapper>
          );
        })}
    </Inner>
  </Container>
);

class EntriesCTA extends Component {
  render() {
    let { id, marginTop, topTitle, title, entries, threeCols } = this.props;

    if (!entries) {
      return null;
    }

    const useQuery = scanArrayOfObjects('categoryId', entries);
    let categoryId = null;

    if (useQuery) {
      const _server = getServer();
      categoryId = _server.inspoCatId;
    }

    if (threeCols === undefined) {
      threeCols = entries.length === 3 || entries.length > 4;
    }

    return useQuery ? (
      <Query variables={{ id: categoryId }} query={query}>
        {result => {
          const rawCategories = _.get(result.data, 'categories');
          let updatedEntries = [];

          if (rawCategories && entries) {
            const orderedCategories = _.keyBy(rawCategories, 'id');

            updatedEntries = entries.map((item, i) => {
              if (item.categoryId !== undefined) {
                const current = orderedCategories[item.categoryId];
                if (current) {
                  let { id, name, mainHeader, images, aspect } = current;
                  const topTitle = mainHeader !== name ? mainHeader : '';

                  return {
                    id: id,
                    topTitle: topTitle,
                    title: name,
                    link: {
                      to: current.primaryRoute.path
                    },
                    image: images
                      ? {
                          url: images[images.length - 1].url,
                          alt: name,
                          aspect: aspect ? aspect : '7:5'
                        }
                      : null,
                    category: current
                  };
                }
              }

              return item;
            });
          }

          return (
            <RenderEntries
              id={id}
              marginTop={marginTop}
              topTitle={topTitle}
              title={title}
              entries={updatedEntries}
              threeCols={threeCols}
            />
          );
        }}
      </Query>
    ) : (
      <RenderEntries
        id={id}
        marginTop={marginTop}
        topTitle={topTitle}
        title={title}
        entries={entries}
        threeCols={threeCols}
      />
    );
  }
}

export default EntriesCTA;
