import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React, { Fragment } from 'react';
import { useQuery } from 'react-apollo';
import LoadingPage from '../LoadingPage';
import startPageQuery from './StartPageQuery.gql';

import CTA from '../Content/CTA';
import EntriesCTA from '../Content/EntriesCTA';
import EntriesMasonry from '../Content/EntriesMasonry';
import ProductCarousel from '../Content/ProductCarousel';
import ProductGrid from '../Content/ProductGrid';
import Newsletter from '../Content/Newsletter';
import TextImage from '../Content/TextImage';
import TextBlock from '../Content/TextBlock';
import HelloRetailRecommendations from '../HelloRetail';
import RenderCustomElements from '../ContentBlocks/RenderCustomElements';

const startPageComponents = {
  CTA: CTA,
  EntriesCTA: EntriesCTA,
  EntriesMasonry: EntriesMasonry,
  ProductCarousel: ProductCarousel,
  ProductGrid: ProductGrid,
  Newsletter: Newsletter,
  TextImage: TextImage,
  TextBlock: TextBlock,
  HelloRetail: HelloRetailRecommendations
};

const StartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId }
  });

  const items = result?.data?.startPage?.data?.items;

  return (
    <Fragment>
      <RenderCustomElements items={items}></RenderCustomElements>
      {items?.length == 0 && (
        <StartPageRenderer
          result={result}
          startPageComponents={startPageComponents}
          LoadingPage={LoadingPage}
        />
      )}

      {/* <Newsletter title={"Prenumerera på vårt nyhetsbrev"} topTitle={"INSPIRERANDE NYHETER OCH ERBJUDANDEN!"} marginTop={"md"} marginBottom={"md"}></Newsletter> */}

      {/* <Query
        variables={startPageId && { startPageId: startPageId }}
        query={startPageQuery}
      >
        {result => {
          console.log(result);
          return (
            // <StartPageRenderer
            //   result={result}
            //   startPageComponents={startPageComponents}
            //   LoadingPage={LoadingPage}
            // />
            <div></div>
          );
        }}
      </Query> */}
    </Fragment>
  );
};

export default StartPage;
