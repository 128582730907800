import React, { Component } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import Container from '../Layout/Container';
import NewsletterField from '../Cart/Newsletter/NewsletterField';
import Text from '../ui/Text';

const NewsletterWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 160px);
  max-width: calc(100% + 20px);
  padding: 50px 10px 30px;
  margin-left: -10px;
  color: ${({ theme }) => theme.colors.white};
  // background: linear-gradient(
  //   to right,
  //   ${({ theme }) => theme.colors.gradientBlack1},
  //   ${({ theme }) => theme.colors.gradientBlack2}
  // );
  transition: all 250ms ease;

  ${({ theme }) => theme.below.md} {
    text-align: center;
    margin-bottom: 40px;
  }

  ${({ theme }) => theme.above.sm} {
    max-width: calc(100% + 30px);
    margin-left: -15px;
  }

  ${({ theme }) => theme.above.md} {
    padding: 45px 15px 45px 55px;
  }

  ${({ theme }) => theme.above.lg} {
    max-width: calc(100% + 160px);
    padding: 80px;
    margin-left: -80px;
  }
`;

const Content = styled('div')`
  ${({ theme }) => theme.below.md} {
    min-width: 100%;
    margin-bottom: 40px;
  }

  ${({ theme }) => theme.above.lg} {
    margin-left: 80px;
  }
`;

const TopTitle = styled('h3')``;

const Title = styled('h2')`
  font-weight: 700;
`;

const StyledText = styled(Text)`
  margin-top: 10px;
`;

const resetPadding = css`
  padding-left: 0px !important;
  padding-right: 0px !important;
`;

const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.colors.darkGrey};
`;

class Newsletter extends Component {
  render() {
    let {
      id,
      marginTop,
      title,
      topTitle,
      text,
      placeholder,
      confirmText
    } = this.props;

    topTitle =
      topTitle !== undefined
        ? topTitle
        : 'INSPIRERANDE NYHETER OCH ERBJUDANDEN!';
    title =
      title !== undefined
        ? title
        : 'Prenumerera på nyhetsbrevet från Bokhandeln';

    return (
      <Wrapper>
        <Container id={id} maxWidth marginTop={marginTop}>
          <NewsletterWrapper>
            <Content>
              {topTitle && <TopTitle>{topTitle}</TopTitle>}

              {title && <Title>{title}</Title>}

              {text && <StyledText content={text} small={true} />}
            </Content>

            <NewsletterField
              placeholder={placeholder}
              confirmText={confirmText}
            />
          </NewsletterWrapper>
        </Container>
      </Wrapper>
    );
  }
}

export default Newsletter;
