import React, { Component, Fragment } from 'react';
import CategoryHeader from '../CategoryPage/CategoryHeader';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import ResultsContainer, {
  Container,
  InnerContainer
} from './ResultsContainer';
// import { FilterContainer } from './ResultsContainer';
import ProductCarousel from '../Content/ProductCarousel';
import Newsletter from '../Content/Newsletter';
import EntriesMasonry from '../Content/EntriesMasonry';
// import { getServer } from '../utility/functions';
import SubcategoriesLayout from './SubcategoriesLayout';
import FilterContext from '@jetshop/core/components/Filters/FilterContext';
import { ShopConfigContext } from '@jetshop/core/components/ConfigProvider';
import HelloRetailRecommendations from '../HelloRetail';
import styled from 'react-emotion';

const CategoryAdditionalInfo = styled('div')`
  text-align: center;
  max-width: 840px;
  margin: auto;
  font-size: 1.8rem;
  line-height: 1.39;
  letter-spacing: 0.26px;
  padding-left: 10px;
  padding-right: 10px;
`;

const recursiveCategorySearch = (targetCategoryId, category) => {
  let result = null;
  if (category.id === targetCategoryId) {
    result = category.id;
  } else if (category.parent) {
    if (category.parent.id === targetCategoryId) {
      result = category.id;
    } else {
      result = recursiveCategorySearch(targetCategoryId, category.parent);
    }
  }
  return result;
};

class Category extends Component {
  static contextType = FilterContext;

  componentDidMount() {
    this.applyUrlFilters();
  }

  applyUrlFilters() {
    const { category } = this.props;
    const { applyListFilter, applyRangeFilter } = this.context;

    const urlParams = new URLSearchParams(window.location.search);

    const categoryFilter = category.products.filters.find(cat =>
      cat.id.includes('categories')
    );
    if (categoryFilter) {
      const selectedCategoryFilters = [...urlParams.keys()]
        .filter(key => key.includes('list[categories]'))
        .map(key => {
          const id = urlParams.get(key);
          return categoryFilter.items.find(item => item.value === id);
        })
        .filter(f => !!f);

      if (selectedCategoryFilters.length) {
        applyListFilter(categoryFilter.id, selectedCategoryFilters);
      }
    }

    const priceFilter = category.products.filters.find(cat =>
      cat.id.includes('price')
    );

    if (priceFilter) {
      const minPriceKey = [...urlParams.keys()].find(key =>
        key.includes('range[price][0]')
      );
      const minPrice = urlParams.get(minPriceKey);
      const maxPriceKey = [...urlParams.keys()].find(key =>
        key.includes('range[price][1]')
      );
      const maxPrice = urlParams.get(maxPriceKey);

      if (minPrice != null && maxPrice != null) {
        applyRangeFilter(priceFilter.id, minPrice, maxPrice);
      }
    }
  }

  render() {
    const {
      category,
      cacheHit,
      result: { loading, data }
    } = this.props;

    // The category that is the parent of the subcategory links.
    const subcategoryParent = !category.parent?.parent
      ? category
      : category.parent;

    const filters = category.products.filters || [];
    const totalHits = category.products ? category.products.totalResults : 0;

    let listlayout = false;

    const HRparents = data?.route?.parents;

    let categoryParents = null;
    if (HRparents) {
      categoryParents = [...HRparents];
      const currentCategory = {
        id: '1' + category?.breadcrumbText,
        object: { breadcrumbText: category.breadcrumbText }
      };
      categoryParents.unshift(currentCategory);
    }

    const categoryContentSplitter = (index = 0) => {
      try {
        return category.content.split('<hr />')[index];
      } catch (error) {
        return null;
      }
    };

    return (
      <ShopConfigContext.Consumer>
        {({ routes: { brandsCategory, authorsCategory } }) => {
          const subcategoriesLayoutIds = [
            brandsCategory.id,
            authorsCategory.id
          ];
          const useSubcategoriesLayout = subcategoriesLayoutIds.includes(
            category.id
          );

          return (
            <Fragment>
              <Container maxWidth marginTop={'xs'}>
                {/* {filters.length && !useSubcategoriesLayout ? (
                  <Above breakpoint="md">
                    <FilterContainer />
                  </Above>
                ) : null} */}

                <InnerContainer noFilters={useSubcategoriesLayout}>
                  {!cacheHit ? (
                    // Show a dummy header if we don't have cached data
                    <CategoryHeaderLoadingState />
                  ) : (
                    <CategoryHeader
                      title={category.name}
                      breadcrumbText={category.breadcrumbText}
                      parents={data.route.parents}
                      totalHits={totalHits}
                      noun={'produkt'}
                      suffix={'er'}
                      content={categoryContentSplitter(0)}
                      currentCategory={category}
                      showCategoryLinks={!useSubcategoriesLayout}
                      subcategoryParent={subcategoryParent}
                    />
                  )}
                </InnerContainer>
              </Container>

              {cacheHit && !useSubcategoriesLayout && (
                <>
                  {category.products.totalResults >= 1 && (
                    <div>
                      <HelloRetailRecommendations
                        recommendationsList={[
                          {
                            title: 'Populärast inom ' + category.name,
                            recommendationId: 'k6203b41622cae73d2f62efa1',
                            parents: categoryParents ?? data?.route?.parents
                          }
                        ]}
                      />
                    </div>
                  )}
                  <ResultsContainer
                    filters={filters}
                    sortOrders={category.products.sortOrders}
                    totalResults={totalHits}
                    products={category.products.result}
                    listName={category.name}
                    categoryPath={
                      category.isDynamic
                        ? null
                        : category && category.primaryRoute
                    }
                    standardLayout={listlayout ? 'list' : 'grid'}
                    loading={loading}
                  />
                </>
              )}

              {cacheHit && useSubcategoriesLayout && (
                <SubcategoriesLayout subcategories={category.subcategories} />
              )}

              <ProductCarousel products={[]} marginTop={'lg'} />

              {categoryContentSplitter(1)?.length > 0 && (
                <CategoryAdditionalInfo
                  dangerouslySetInnerHTML={{
                    __html: categoryContentSplitter(1)
                  }}
                ></CategoryAdditionalInfo>
              )}
              <Newsletter marginTop={'xl'} />

              <EntriesMasonry marginTop={'lg'} entries={[]} />
            </Fragment>
          );
        }}
      </ShopConfigContext.Consumer>
    );
  }
}

export default Category;
