import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import Container from '../Layout/Container';
import { getStringByPropertyName } from './ContentHelper';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../Theme';
import Link from '../utility/Link';

const ContainerWrapper = styled('div')`
  position: relative;
  width: 100%;
  padding-bottom: 2rem;

  ${theme.below.lg} {
    [data-flight-image-container] {
      height: 40vw !important;
      position: static !important;
    }
  }

  ${theme.below.sm} {
    [data-flight-image-container] {
      height: 50vw !important;
      position: static !important;
    }
  }

  ${theme.below.xs} {
    [data-flight-image-container] {
      height: 250px !important;
      position: static !important;
    }
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.5rem;

  ${theme.below.sm} {
    gap: 1rem;
    margin-top: 1rem;
  }

  a {
    text-decoration: none;
  }

  button {
    padding: 1rem 3rem;
    background-color: #fff;
    border-radius: 100px;
    color: black;
    font-size: min(calc(1vw + 1.02rem), 1.8rem);
    font-weight: bold;
    transition: all 0.4s ease-out;
    display: flex;
    align-items: center;
    gap: 1rem;
    ${theme.below.sm} {
      padding: 0.75rem 2rem;
    }
  }

  button:hover {
    scale: 1.02;
  }
`;

const ContentOverlay = styled(Container)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;

  ${theme.above.md} {
    gap: 1rem;
  }

  gap: 0.25rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
    line-height: 1.21;
  }

  h1 {
    font-size: min(calc(1.1vw + 2rem), 4.6rem);
  }

  h2 {
    font-size: min(calc(0.75vw + 1rem), 2.5rem);
  }
`;

const Banner = ({ properties, children }) => {
  const title = getStringByPropertyName(properties, 'title');
  const subTitle = getStringByPropertyName(properties, 'subTitle');
  const imgSrc = getStringByPropertyName(properties, 'image');
  const imgAlt = getStringByPropertyName(properties, 'alt');

  return (
    <ContainerWrapper>
      <Image src={imgSrc} alt={imgAlt} aspect="2543:800" cover={true}></Image>
      <ContentOverlay>
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
        <ButtonContainer>
          {children.map((child, key) => {
            const link = getStringByPropertyName(child?.properties, 'link');
            const title = getStringByPropertyName(
              child?.properties,
              'ctaTitle'
            );
            return (
              <Link to={link} key={key}>
                <button>{title}</button>
              </Link>
            );
          })}
        </ButtonContainer>
      </ContentOverlay>
    </ContainerWrapper>
  );
};

export default Banner;
