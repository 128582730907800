import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import Container from '../Layout/Container';
import { getStringByPropertyName } from './ContentHelper';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../Theme';
import Link from '../utility/Link';

const ContainerWrapper = styled(Container)`
  padding: 4rem 0rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 2rem;

  a {
    color: inherit;
  }

  ${theme.below.sm} {
    padding: 2rem 0rem;
    gap: 3rem;
  }
`;

const Card = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.4s ease-out;
  &:hover {
    scale: 1.01;
  }
`;

const CardWrapper = styled('div')`
  flex: ${props => props.flexWidth};
  max-width: ${props => props.maxWidth};

  [data-flight-image-container] {
    height: 100% !important;
  }

  [data-flight-image-container] img {
    border-radius: 0.5rem;
  }

  ${theme.below.lg} {
    flex: 48%;
  }

  ${theme.below.sm} {
    flex: 100%;
    max-width: 100%;
  }
`;

const ContentOverlay = styled('div')`
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  z-index: 1;
  // ${theme.below.xxs} {
  //   justify-content: center;
  // }
`;

const ContentContainer = styled('div')`
  width: fit-content;
  background-color: #00000050;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 2rem;
  border-radius: 0.5rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  h6 {
    color: white;
    ine-height: 1.21;
  }

  h1 {
    font-size: min(calc(1.1vw + 2rem), 4.6rem);
    font-weight: 700;
    margin-bottom: 1.0rem;
    ${theme.below.md} {
      margin-bottom: 0rem;
    }
  }

  p {
    margin-bottom: 1.0rem;
  }

  ${theme.below.sm} {
    p {
      font-size: 14px;
    }
  }

  // background-image: linear-gradient(
  //   to bottom,
  //   rgba(117, 126, 127, 0),
  //   rgba(0, 0, 0, 0.8)
  // );

  ${theme.below.xs} {
    padding: 1rem;
    margin: 1rem;
  }

  // ${theme.below.xxs} {
  //   margin: 0rem;
  // }
`;

const CtaDiv = styled('div')`
  transition: all 0.2s ease-in;
  padding: 15px;
  background-color: #ffffff;
  text-align: center;
  padding: 1rem 4rem;
  border-radius: 100px;
  width: fit-content;
  font-size: 16px;

  ${theme.below.md} {
    padding: 1rem 2rem;
  }

  ${theme.below.sm} {
    font-size: 14px;
  }
`;

const getCssWidth = width => {
  switch (width) {
    case '100%':
      return '100%';
    case '75%':
      return '74%';
    case '50%':
      return '49%';
    case '25%':
      return '24%';
    default:
      return '49%';
  }
};

const ImageGrid = ({ properties, children }) => {
  return (
    <ContainerWrapper>
      {children.map((child, i) => {
        const subTitle = getStringByPropertyName(child?.properties, 'subtitle');
        const title = getStringByPropertyName(child?.properties, 'title');
        const ctaTitle = getStringByPropertyName(child?.properties, 'ctaTitle');
        const link = getStringByPropertyName(child?.properties, 'link');
        const imgUrl = getStringByPropertyName(child?.properties, 'image');
        const imgAlt = getStringByPropertyName(child?.properties, 'alt');
        const width = getStringByPropertyName(child?.properties, 'width');
        const cssWidth = getCssWidth(width);

        return (
          <CardWrapper flexWidth={cssWidth} maxWidth={width} key={i}>
            <Card className="st-shadow">
              <Link to={link}>
                <Image
                  src={imgUrl}
                  alt={imgAlt}
                  aspect="21:9"
                  cover={true}
                ></Image>
                <ContentOverlay>
                  <ContentContainer>
                    <h1>{title}</h1>
                    <p>{subTitle}</p>
                    <CtaDiv className="st-shadow">
                      <span>{ctaTitle}</span>
                    </CtaDiv>
                  </ContentContainer>
                </ContentOverlay>
              </Link>
            </Card>
          </CardWrapper>
        );
      })}
    </ContainerWrapper>
  );
};

export default ImageGrid;
