import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import Container from '../Layout/Container';
import Text from '../ui/Text';
import TitleRow from '../ui/TitleRow';

const TitleText = styled('p')`
  text-transform: uppercase;
  margin: 0 20px;
  font-size: 34px;
  font-weight: 500;
  color: #333;
`;
const Divider = styled('div')`
  border-top: 1px solid #cecece;
`;

const Title = ({ title }) => (
  <div
    css={css`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
    `}
  >
    <Divider
      css={css`
        flex: 1;
      `}
    />
    <TitleText>{title}</TitleText>
    <Divider
      css={css`
        flex: 1;
      `}
    />
  </div>
);

const TextBlock = ({ title, text }) => {
  return (
    <Container
      marginTop="sm"
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <TitleRow topTitle={title} title=" " />
      <Text
        content={text}
        className={css`
          font-size: 16px;
          margin: 4rem 0;
          text-align: center;
        `}
      />
    </Container>
  );
};

export default TextBlock;
